import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";

type Props = {
  children: React.ReactNode;
};

export default function Layout(props: Props) {
  const { children } = props;
  return (
    <div>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" href="apple-touch-icon.png" />
        <title>ロイヤルミハランド ＜Royal MihaLand＞</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta
          name="description"
          content="ロイヤルミハランドとは、長崎県三原にある『三原庭園』を中心に作られている小さな国であり、街のことを指します。国王は世界的に有名な庭園デザイナー石原和幸のもう一つの姿ではありますが、普段の気鋭の庭園デザイナーである石原先生とは異なり、ちょっとお茶目で面白い性格の持ち主。
      ロイヤルミハランドが今後どのように発展していくのか、どんなストーリーが生まれるのか、国王自らの動画も発信していきますので、ロイヤルミハランドのこれからをお楽しみに！またロイヤルミハランドと共に『三原庭園』へのご来場も心よりお待ちしております！"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&display=swap&subset=japanese"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="/css/destyle.css"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          href="/css/style.css"
          type="text/css"
          media="all"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://mihaland.ishihara28.com/" />
        <meta
          property="og:title"
          content="ロイヤルミハランド＜Royal MihaLand＞"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mihaland.ishihara28.com/" />
        <meta property="og:image" content="img/ogp.jpg" />
        <meta
          property="og:site_name"
          content="ロイヤルミハランド＜Royal MihaLand＞"
        />
        <meta
          property="og:description"
          content="ロイヤルミハランドとは、長崎県三原にある『三原庭園』を中心にした小さな国で、国王は世界的に有名な庭園デザイナー石原和幸のもう一つの姿です。"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="ロイヤルミハランド＜Royal MihaLand＞"
        />
        <meta
          name="twitter:description"
          content="ロイヤルミハランドとは、長崎県三原にある『三原庭園』を中心にした小さな国で、国王は世界的に有名な庭園デザイナー石原和幸のもう一つの姿です。"
        />
        <meta name="twitter:image" content="img/ogp.jpg" />
        <meta itemProp="image" content="img/ogp.jpg" />
        <script
          type="text/javascript"
          src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"
        ></script>
        <script src="/js/jquery.inview.js" type="text/javascript"></script>
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&display=swap&subset=japanese"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />

        <script type="text/javascript" src="js//js/jquery.matchHeight.js" />
      </Helmet>

      {/* Global site tag (gtag.js) - Google Analytics */}
      <a name="top" id="top">
        ""
      </a>
      <div id="wrapper">
        <Header />
        {children}
        <footer>
          <div className="p-top">
            <a href="#top">
              <img src="img/page_up.png" alt="" width={40} />
            </a>
          </div>
          <div className="footer-box">
            <div className="inner">
              <ul className="footer-nav-1 mb10">
                <li>
                  <a href="/">ホーム</a>
                </li>
                <li>
                  <ul className="footer-nav-2">
                    <li>
                      <a href="area_1.html">三原の泉</a>
                    </li>
                    <li>
                      <a href="area_2.html">三原の楽園</a>
                    </li>
                    <li>
                      <a href="area_3.html">王宮</a>
                    </li>
                    <li>
                      <a href="area_4.html">Bar MIHARA GARDEN</a>
                    </li>
                    <li>
                      <a href="area_5.html">国王の宝物</a>
                    </li>
                    <li>
                      <a href="area_6.html">バッキンガム宮殿</a>
                    </li>
                    <li>
                      <a href="area_7.html">占いの館</a>
                    </li>
                    <li>
                      <a href="area_8.html">果物畑</a>
                    </li>
                    <li>
                      <a href="area_9.html">三原ファーム</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul className="footer-nav-2">
                    <li>
                      <a href="messege.html">国王からのメッセージ</a>
                    </li>
                    <li>
                      <a href="about.html">ロイヤルミハランドとは</a>
                    </li>
                    <li>
                      <a href="recruitment.html">国民募集</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://www.kaza-hana.jp/">石原和幸デザイン研究所</a>
                </li>
              </ul>
            </div>
          </div>
          <address className="aC t10 white">© ISHIHARA INC.</address>
        </footer>
        {/* /footer*/}
      </div>
      {/* /#wrapper */}
    </div>
  );
}
